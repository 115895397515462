import React, { useState } from "react";
import { Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

const GrowthStory = () => {
  const [openYear, setOpenYear] = useState(null);

  const toggleDropdown = (year) => {
    setOpenYear(openYear === year ? null : year);
  };
  return (
    <section className="growth-story">
      <div className="container">
        <div className="title-container">
          <h2 className="text-yellow">GROWTH STORY</h2>
        </div>
        <div className="row">
          <p className="outlet-info">
            25+ Outlets in Thane, Mumbai, Navi Mumbai & Pune
          </p>
          <div className="timeline">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div
                    className="year-header"
                    onClick={() => toggleDropdown(2019)}
                  >
                    <span className="text-yellow"> Year: 2019</span> 2 Outlets
                  </div>
                </Accordion.Header>
                {openYear === 2019 && (
                  <Accordion.Body>
                    <div className="milestones">
                      <div className="milestone">
                        <h3>MARCH</h3>
                        <p>Startup</p>
                        <p>FOOD TRUCK</p>
                      </div>
                      <div className="milestone">
                        <h3>JULY</h3>
                        <p>OUTLET NO. 1</p>
                        <p>VASANT VIHAR, THANE</p>
                      </div>
                      <div className="milestone">
                        <h3>DECEMBER</h3>
                        <p>OUTLET NO. 2</p>
                        <p>HARINIWAS CIRCLE, THANE</p>
                      </div>
                    </div>
                  </Accordion.Body>
                )}
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div
                    className="year-header"
                    onClick={() => toggleDropdown(2021)}
                  >
                    <span className="text-yellow">Year: 2021 </span>
                    <span>2 Outlets, 4 Franchises</span>
                  </div>
                </Accordion.Header>
                {openYear === 2021 && (
                  <Accordion.Body>
                    <div className="milestones">
                      <div className="milestone">
                        <h3>MARCH</h3>
                        <p>Startup</p>
                        <p>FOOD TRUCK</p>
                      </div>
                      <div className="milestone">
                        <h3>JULY</h3>
                        <p>OUTLET NO. 1</p>
                        <p>VASANT VIHAR, THANE</p>
                      </div>
                      <div className="milestone">
                        <h3>DECEMBER</h3>
                        <p>OUTLET NO. 2</p>
                        <p>HARINIWAS CIRCLE, THANE</p>
                      </div>
                    </div>
                  </Accordion.Body>
                )}
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <div
                    className="year-header"
                    onClick={() => toggleDropdown(2022)}
                  >
                    <span className="text-yellow">Year: 2022 </span>
                    <span>5 Franchises</span>
                  </div>
                </Accordion.Header>
                {openYear === 2022 && (
                  <Accordion.Body>
                    <div className="milestones">
                      <div className="milestone">
                        <h3>MARCH</h3>
                        <p>Startup</p>
                        <p>FOOD TRUCK</p>
                      </div>
                      <div className="milestone">
                        <h3>JULY</h3>
                        <p>OUTLET NO. 1</p>
                        <p>VASANT VIHAR, THANE</p>
                      </div>
                      <div className="milestone">
                        <h3>DECEMBER</h3>
                        <p>OUTLET NO. 2</p>
                        <p>HARINIWAS CIRCLE, THANE</p>
                      </div>
                    </div>
                  </Accordion.Body>
                )}
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div
                    className="year-header"
                    onClick={() => toggleDropdown(2023)}
                  >
                    <span className="text-yellow">Year: 2023 </span>
                    <span>11 Franchises</span>
                  </div>
                </Accordion.Header>
                {openYear === 2023 && (
                  <Accordion.Body>
                    <div className="milestones">
                      <div className="milestone">
                        <h3>MARCH</h3>
                        <p>Startup</p>
                        <p>FOOD TRUCK</p>
                      </div>
                      <div className="milestone">
                        <h3>JULY</h3>
                        <p>OUTLET NO. 1</p>
                        <p>VASANT VIHAR, THANE</p>
                      </div>
                      <div className="milestone">
                        <h3>DECEMBER</h3>
                        <p>OUTLET NO. 2</p>
                        <p>HARINIWAS CIRCLE, THANE</p>
                      </div>
                    </div>
                  </Accordion.Body>
                )}
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <div
                    className="year-header"
                    onClick={() => toggleDropdown(2024)}
                  >
                    <span className="text-yellow">Year: 2024 </span>
                    <span>5 Franchises</span>
                  </div>
                </Accordion.Header>
                {openYear === 2024 && (
                  <Accordion.Body>
                    <div className="milestones">
                      <div className="milestone">
                        <h3>MARCH</h3>
                        <p>Startup</p>
                        <p>FOOD TRUCK</p>
                      </div>
                      <div className="milestone">
                        <h3>JULY</h3>
                        <p>OUTLET NO. 1</p>
                        <p>VASANT VIHAR, THANE</p>
                      </div>
                      <div className="milestone">
                        <h3>DECEMBER</h3>
                        <p>OUTLET NO. 2</p>
                        <p>HARINIWAS CIRCLE, THANE</p>
                      </div>
                    </div>
                  </Accordion.Body>
                )}
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GrowthStory;
