import React from "react";
import MenuCard from "./Categorycard";

const CategoryCarousel = ({ menuItems }) => {
  return (
    <div className="catecontainer">
      <h2 className="text-center mb-4">Our Special Menu</h2>
      <div className="d-flex overflow-auto">
        {menuItems.map((item, index) => (
         <a href="#menus" > <MenuCard
            key={index}
            title={item.title}
            imageUrl={item.imageUrl}
            products={item.products}
            isActive={item.isActive}
          /></a>
        ))}
      </div>
    </div>
  );
};

export default CategoryCarousel;
