import React from "react";
import rupees from "../assets/img/rupees.png";
import fresh from "../assets/img/fresh.png";
import fast_delivery from "../assets/img/fast-delivery.png";
import quality from "../assets/img/quality.png";

const AboutUs = () => {
  return (
    <section className="about-us" id="about-us">
      <div className="title-container">
        <h2 className="section-title">ABOUT US</h2>
      </div>
      <div class="row">
        <div class="col-lg-3 col-sm-6 m-b30">
          <div class="icon-bx-wraper style-3">
            <div class="icon-bx">
              <div class="icon-cell">
                <img src={quality} class="flaticon-cuisine"/>
              </div>
            </div>
            <div class="icon-content">
              <h5 class="title">Super Quality Food</h5>
              <p>
                Arbab's Shawarma, Served Testy Food & good food by friendly
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 m-b30">
          <div class="icon-bx-wraper style-3">
            <div class="icon-bx">
              <div class="icon-cell">
              <img src={fresh} class="flaticon-cuisine"/>
              </div>
            </div>
            <div class="icon-content">
              <h5 class="title">Organic Vegetables</h5>
              <p>Healthy, fresh, and organically grown vegetables.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 m-b30">
          <div class="icon-bx-wraper style-3">
            <div class="icon-bx">
              <div class="icon-cell">
              <img src={fast_delivery} class="flaticon-cuisine"/>

              </div>
            </div>
            <div class="icon-content">
              <h5 class="title">Fast Delivery</h5>
              <p>With our Delivery Partner Crisfood, Swiggy, Zomato, Jedlo</p>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 m-b30">
          <div class="icon-bx-wraper style-3">
            <div class="icon-bx">
              <div class="icon-cell">
              <img src={rupees} class="flaticon-cuisine"/>
              </div>
            </div>
            <div class="icon-content">
              <h5 class="title">Affordable Prices</h5>
              <p>Get the best products at the most affordable prices.</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container">
        <div className="content-container">
          <div className="col-md-6 ">
            <div className="content-column">
              <p>
                <img src={shawarma} alt="Shawarma Icon" className="icon" />
                We serve unique Indo-Lebanese shawarmas with fresh flavors,
                attractive branding, convenient packaging, and value price
                points.
              </p>
            </div>
            <div className="content-column">
              <p>
                <img src={shawarma} alt="Shawarma Icon" className="icon" />
                Arbab's Shawarma, a quick-service restaurant serving
                Indo-Lebanese cuisine.
              </p>
            </div>

            <div className="content-column">
              <p>
                <img src={shawarma} alt="Shawarma Icon" className="icon" />
                PERPETUAL VISION LLP group has a licensed trademark of Arbab's
                Shawarma.
              </p>
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="content-column">
              <p>
                <img src={shawarma} alt="Shawarma Icon" className="icon" />
                The model delivers high quality, freshly made, and value for
                money Indo-Lebanese food through 25+ outlets operational in
                Thane, Mumbai, Navi Mumbai, and Pune.
              </p>
            </div>
            <div className="content-column">
              <p>
                <img src={shawarma} alt="Shawarma Icon" className="icon" />
                Online presence and collaborations with Swiggy, Zomato. It helps
                Arbab's Shawarma to enter a new business vertical, which will
                lead to an increase in revenues and profitability.
              </p>
            </div>
            <div class="col-lg-3 col-sm-6 m-b30">
              <div class="icon-bx-wraper style-3">
                <div class="icon-bx">
                  <div class="icon-cell">
                    <i class="flaticon-cuisine"></i>
                  </div>
                </div>
                <div class="icon-content">
                  <h5 class="title">
                    Fresh Products
                  </h5>
                  <p>Lorem Ipsum is simply dummy text of the printing and </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* </div> */}
    </section>
  );
};

export default AboutUs;
