import React, { useEffect, useState } from "react";
import { Carousel, Col } from "react-bootstrap";
import sallad from "../assets/img/platters.png";
import shawarma from "../assets/img/shawa.png";
import PLATTER from "../assets/img/sallad.png";
// import Tomato from "../assets/img/Group 1.png";

const AnimatedSlider = () => {
  const whatsappNumber = "1234567890";
  const message =
    "Hello! I would like to inquire about your Shawarma franchise.";

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="courContainer"
      style={{ position: "relative", overflow: "hidden" }}
    >
      <div className="row">
        {/* First Column for Text */}
        <div className="carol">
          <div className="col-md-6">
            <div className="firscol6">
              <h1
                className="display-4 mb-4"
                style={{ color: "#000", fontWeight: "700" }}
              >
                Your Shawarma Craving Ends Here...
              </h1>
              <p className="lead mb-4" style={{ fontWeight: "400" }}>
                Launched in 2019, Arbab's Shawarma is set to revolutionize the
                franchise industry. Join hands with Arbab's Shawarma and become
                our valued <strong>Arbab's SHAWARMA PARTNER</strong>.
              </p>
              <a
                href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
                  message
                )}`}
                className="btn "
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Inquiry
              </a>
            </div>
          </div>

          {/* Second Column for Carousel */}
          <Col md={6}>
            <Carousel
              controls={false}
              interval={3000}
              slide={false}
              indicators={false}
            >
              <Carousel.Item>
                <img className="carousel-img" src={sallad} alt="First slide" />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="carousel-img"
                  src={shawarma}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img className="carousel-img" src={PLATTER} alt="Third slide" />
              </Carousel.Item>
            </Carousel>
          </Col>

          {/* Parallax Image with srcSet */}
          <div
            className="parallax-image"
            style={{
              position: "absolute",
              top: 0,
              left: 700,
              width: "50%",
              height: "100%",
              zIndex: 1,
              transform: `translateY(${scrollY * 0.5}px)`, // Parallax scroll effect
              pointerEvents: "none", // Prevent interaction with the image
            }}
          >
            <div className="image">
              <img
                decoding="async"
                width="874"
                height="531"
                src="https://demo2.pavothemes.com/poco/wp-content/uploads/2020/08/h2_shallot.png"
                alt=""
                sizes="(max-width: 874px) 100vw, 874px"
                srcSet="https://demo2.pavothemes.com/poco/wp-content/uploads/2020/08/h2_shallot.png 874w, https://demo2.pavothemes.com/poco/wp-content/uploads/2020/08/h2_shallot-300x182.png 300w, https://demo2.pavothemes.com/poco/wp-content/uploads/2020/08/h2_shallot-768x467.png 768w, https://demo2.pavothemes.com/poco/wp-content/uploads/2020/08/h2_shallot-800x486.png 800w"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimatedSlider;
