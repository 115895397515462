/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable react/style-prop-object */
import React from "react";
import shop from "../assets/img/shop.png";
import FormfacadeEmbed from "@formfacade/embed-react";

const GoogleForm = () => {
  return (
    <div className="form" id="franchiseform">
      <div className="form text-center pt-3">
        <h2>BECOME A PARTNER</h2>
      </div>

      <h3 className="subtitle text-center pb-3">Franchise Inquiry Form</h3>

      <div className="formcontainer">
        <div className="row g-0">
          <div className="formcontain">
            <div className="col-md-6">
              <div className="formimg-fluid">
                <img src={shop} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              {/* <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSc-5fGmv-6rEZWvm7D5oy-QHy61x2zI9XeXoQJ0tIDNHsW8vA/viewform?embedded=true"
            width="640"
            height="640px"
            title="Google Form"
            className="iframe-container"
          ></iframe> */}
              <FormfacadeEmbed
                formFacadeURL="https://formfacade.com/include/109089996487212761843/form/1FAIpQLSf-fx33UEJ2v8KHjioqYmAruJR-HofYBO8tWY4VM3pC0asxJg/classic.js/?div=ff-compose"
                onSubmitForm={() => console.log("Form submitted")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    // </section>
    // <div class="container contact-area bg-parallax">
    //   <div class="row align-items-center">
    //     <div class="col-lg-8 col-md-12 m-b30">
    //       <div class="contact-head">
    //         <h4 class="title text-white wow fadeInUp">Book a Table</h4>
    //         <p class="text-white opacity-75 wow fadeInUp">
    //           Lorem Ipsum is that it has a more-or-less normal distribution of
    //           letters, to using making it look like readable English.
    //         </p>
    //       </div>
    //       <form action="#">
    //         <div class="row">
    //           <div class="col-lg-6 col-md-6 m-b30 m-xl-b50 wow fadeInUp">
    //             <div class="input-group input-line">
    //               <div class="input-group-prepand">
    //                 <i class="flaticon-user"></i>
    //               </div>
    //               <input
    //                 name="dzFirstName"
    //                 required=""
    //                 type="text"
    //                 class="form-control"
    //                 placeholder="Your Name"
    //               />
    //             </div>
    //           </div>
    //           <div class="col-lg-6 col-md-6 m-b30 wow fadeInUp">
    //             <div class="input-group input-line">
    //               <div class="input-group-prepand">
    //                 <i class="flaticon-phone-call"></i>
    //               </div>
    //               <input
    //                 name="dzPhoneNumber"
    //                 required=""
    //                 type="text"
    //                 class="form-control dz-number"
    //                 placeholder="Phone Number"
    //               />
    //             </div>
    //           </div>
    //           <div class="col-lg-6 col-md-6 m-b30 wow fadeInUp">
    //             <div class="input-group input-line">
    //               <div class="input-group-prepand">
    //                 <i class="flaticon-two-people"></i>
    //               </div>
    //               <div class="form-select default-select select-option-rsuite rs-picker rs-picker-select rs-picker-default rs-picker-toggle-wrapper rs-picker-has-value rs-picker-cleanable">
    //                 <div
    //                   role="combobox"
    //                   aria-haspopup="listbox"
    //                   aria-expanded="false"
    //                   tabindex="0"
    //                   class="rs-picker-toggle rs-btn rs-btn-default"
    //                 >
    //                   <div class="rs-stack">
    //                     <div class="rs-stack-item">
    //                       <input
    //                         type="text"
    //                         autocomplete="off"
    //                         aria-hidden="true"
    //                         readonly=""
    //                         tabindex="-1"
    //                         class="rs-picker-toggle-textbox rs-picker-toggle-read-only"
    //                         value="Number Of People"
    //                       />
    //                       <span
    //                         class="rs-picker-toggle-value"
    //                         aria-placeholder="Number Of People"
    //                       >
    //                         Number Of People
    //                       </span>
    //                     </div>
    //                     <div class="rs-stack-item">
    //                       <span
    //                         role="button"
    //                         tabindex="-1"
    //                         class="rs-picker-toggle-clean rs-btn-close"
    //                         title="Clear"
    //                         aria-label="Clear"
    //                       >
    //                         <svg
    //                           width="1em"
    //                           height="1em"
    //                           viewBox="0 0 16 16"
    //                           fill="currentColor"
    //                           aria-hidden="true"
    //                           focusable="false"
    //                           class="rs-icon"
    //                           aria-label="close"
    //                           data-category="application"
    //                         >
    //                           <path d="M2.784 2.089l.069.058 5.146 5.147 5.146-5.147a.5.5 0 01.765.638l-.058.069L8.705 8l5.147 5.146a.5.5 0 01-.638.765l-.069-.058-5.146-5.147-5.146 5.147a.5.5 0 01-.765-.638l.058-.069L7.293 8 2.146 2.854a.5.5 0 01.638-.765z"></path>
    //                         </svg>
    //                       </span>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div class="col-lg-6 col-md-6 m-b30 wow fadeInUp">
    //             <div class="input-group input-line">
    //               <div class="input-group-prepand">
    //                 <i class="flaticon-email-1"></i>
    //               </div>
    //               <input
    //                 name="dzEmail"
    //                 required=""
    //                 type="text"
    //                 class="form-control"
    //                 placeholder="Your Email"
    //               />
    //             </div>
    //           </div>
    //           <div class="col-lg-6 col-md-6 m-b30 wow fadeInUp">
    //             <div class="input-group input-line">
    //               <div class="input-group-prepand">
    //                 <i class="flaticon-clock"></i>
    //               </div>
    //               <input
    //                 required=""
    //                 type="text"
    //                 class="form-control"
    //                 id="timePickerOnly"
    //                 placeholder="Time"
    //               />
    //             </div>
    //           </div>
    //           <div class="col-lg-6 col-md-6 m-b30 wow fadeInUp">
    //             <div class="input-group input-line">
    //               <div class="input-group-prepand">
    //                 <i class="flaticon-calendar-date"></i>
    //               </div>
    //               <input
    //                 required=""
    //                 type="text"
    //                 class="form-control"
    //                 id="datePickerOnly"
    //                 placeholder="Date"
    //               />
    //             </div>
    //           </div>
    //           <div class="col-lg-12 col-md-12 ">
    //             <button
    //               type="reset"
    //               class="btn  btn-primary btn-hover-3 mt-3"
    //             >
    //               <span class="btn-text" data-text="Book a Table">
    //                 Book a Table
    //               </span>
    //             </button>
    //           </div>
    //         </div>
    //       </form>
    //     </div>
    //     <div class="col-lg-4 col-md-12">
    //       <div class="widget widget_working  wow fadeInUp">
    //         <div class="head">
    //           <h5 class="title text-white">Contact Info</h5>
    //           <p class="text-white opacity-75">
    //             Lorem Ipsum is simply dummy text of the printing typesetting
    //             industry.
    //           </p>
    //         </div>
    //         <ul>
    //           <li>
    //             <i class="flaticon-placeholder"></i>
    //             <p>
    //               1247/Plot No. 39, 15th Phase, Colony, Kkatpally, Hyderabad
    //             </p>
    //           </li>
    //           <li>
    //             <i class="flaticon-telephone"></i>
    //             <p>+91 987-654-3210 +91 123-456-7890</p>
    //           </li>
    //           <li>
    //             <i class="flaticon-email-1"></i>
    //             <p>info@example.com info@example.com</p>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default GoogleForm;
